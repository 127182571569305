document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.uta-product-block').forEach(function (block) {
        // Controleer of het om een grid-layout gaat of een standaard layout
        if (block.querySelector('.uta-products-grid-column') || block.querySelector('.uta-products-list-container')) {
            // Grid Layout
            block.querySelectorAll('.uta-products-grid-column').forEach(function (productColumn) {
                const showMoreLink = productColumn.querySelector('.show-more-merchants');
                const showLessLink = productColumn.querySelector('.show-less-merchants');
                const hiddenMerchants = productColumn.querySelectorAll('.uta-product-price-link.uta-product-hidden');

                if (showMoreLink && showLessLink) {
                    showMoreLink.addEventListener('click', function (e) {
                        e.preventDefault();
                        hiddenMerchants.forEach(function (merchant) {
                            merchant.classList.remove('uta-product-hidden');
                        });
                        showMoreLink.classList.add('uta-product-hidden');
                        showLessLink.classList.remove('uta-product-hidden');
                    });

                    showLessLink.addEventListener('click', function (e) {
                        e.preventDefault();
                        hiddenMerchants.forEach(function (merchant) {
                            merchant.classList.add('uta-product-hidden');
                        });
                        showMoreLink.classList.remove('uta-product-hidden');
                        showLessLink.classList.add('uta-product-hidden');
                    });
                }
            });
        } else {
            const showMoreLink = block.querySelector('.show-more-merchants');
            const showLessLink = block.querySelector('.show-less-merchants');
            const hiddenMerchants = block.querySelectorAll('.uta-product-price-link.uta-product-hidden');

            if (showMoreLink && showLessLink) {
                showMoreLink.addEventListener('click', function (e) {
                    e.preventDefault();
                    hiddenMerchants.forEach(function (merchant) {
                        merchant.classList.remove('uta-product-hidden');
                    });
                    showMoreLink.classList.add('uta-product-hidden');
                    showLessLink.classList.remove('uta-product-hidden');
                });

                showLessLink.addEventListener('click', function (e) {
                    e.preventDefault();
                    hiddenMerchants.forEach(function (merchant) {
                        merchant.classList.add('uta-product-hidden');
                    });
                    showMoreLink.classList.remove('uta-product-hidden');
                    showLessLink.classList.add('uta-product-hidden');
                });
            }
        }
    });
});



/*
document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.uta-product-block').forEach(function (block) {
        const showMoreLink = block.querySelector('.show-more-merchants');
        const showLessLink = block.querySelector('.show-less-merchants');
        const hiddenMerchants = block.querySelectorAll('.uta-product-price-link.uta-product-hidden');

        if (showMoreLink && showLessLink) {
            showMoreLink.addEventListener('click', function (e) {
                e.preventDefault();
                hiddenMerchants.forEach(function (merchant) {
                    merchant.classList.remove('uta-product-hidden');
                });
                showMoreLink.classList.add('uta-product-hidden');
                showLessLink.classList.remove('uta-product-hidden');
            });

            showLessLink.addEventListener('click', function (e) {
                e.preventDefault();
                hiddenMerchants.forEach(function (merchant) {
                    merchant.classList.add('uta-product-hidden');
                });
                showMoreLink.classList.remove('uta-product-hidden');
                showLessLink.classList.add('uta-product-hidden');
            });
        }
    });
});*/
